/*global styles*/

.boxed {
  width: 80%;
  margin: auto;
}

button.link {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-weight: 700;
}

button.link:hover {
  opacity: 0.8;
}

.ant-menu-sub button.link {
  color: #606060;
  font-weight: normal;
}

.ant-menu-sub .ant-menu-item-group-title {
  color: #808080;
}

.ant-menu-sub .button.link {
  color: #000;
}

.ant-layout {
  background: white;
}

.ant-layout-header {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  height: 50px;
  line-height: normal;
  margin-bottom: 24px;
  align-items: center;
  background: white;
}

.loader {
  display: block;
  text-align: center;
  font-size: 40px;
  opacity: 0.5;
}
